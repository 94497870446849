<template>
  <div class="vcard__new__photo">
    <div class="vcard__new__photo__preview">
      <img
        :src="previewImage"
        alt=""
        class="vcard__new__photo__preview__image"
      />
      <input
        type="file"
        name="vcard-thumb"
        id="vcard-thumb"
        v-show="false"
        ref="vcardPreview"
        @change="onFileChange"
      />
      <button @click="chooseImage" class="vcard__new__photo__preview__button">
        Escoger imagen
      </button>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    employeeImage: {
        default: () => null
    }
  },
  
  mounted() {
    if(this.employeeImage) {
        this.previewImage = this.employeeImage
    }
  },

  data() {
    return {
      previewImage:
        "https://firebasestorage.googleapis.com/v0/b/di-medical-del-sur.appspot.com/o/static%2FuserIcon.png?alt=media&token=37e7160e-1ae7-41d3-b8d9-bad1e7662568",
      vCardFile: "",
    };
  },

  methods: {
    chooseImage() {
      this.$refs.vcardPreview.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.vCardFile = file;
      const fr = new FileReader();
      (fr.onload = () => {
        this.previewImage = fr.result;
      }),
        fr.readAsDataURL(file);
        this.nextSection()
    },

    nextSection() {
        this.$emit('employeeImage', this.vCardFile)
    }
  },
};
</script>

<style>
</style>
